@import '../../node_modules/bootstrap/scss/_functions.scss';
@import '../../node_modules/bootstrap/scss/_variables.scss';
@import '../../node_modules/bootstrap/scss/mixins/_breakpoints.scss';

//fonts
$fontfamily: 'IBM Plex Sans', sans-serif;

//colors
$primary-orange: #de3831;
$primary-orange-light: rgba(237, 67, 28, 0.7);

$primary-red: #e10000;

$primary-blue-green: #00a0bc;

$primary-dark: #1b1b1b;

$primary-green: #5b8f22;

$primary-yellow: #ffde14;

$gray-dark: #1b1b1b;
$gray-medium: #616161;
$gray-light: #f1f1f1;
$gray-bar: #cccccc;
$gray-undefined: rgb(172, 180, 188);

$exp-yellow: #ffde14;

$white-base: #ffffff;
$white-drop-shadow: #f1f1f1;

$black: #000000;
$black-active: rgba(0, 0, 0, 0.7);
