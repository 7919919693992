@import './_variables.scss';
@import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
@import '../../node_modules/@fortawesome/fontawesome-svg-core/styles.css';

body {
    font-family: $fontfamily;
}

.overlay {
    .la-ball-square-spin {
        &.la-3x {
            top: 50vh !important;
        }
    }
}

.remove-padding {
    padding-right: 0px;
    padding-left: 0px;
}

.remove-margin {
    margin-left: 0;
    margin-right: 0;
}

.modal-width-900 {
    max-width: 900px;
}

.call-to-action-button {
    width: 221px;
    height: 51px;
    color: $white-base !important;
    font-size: 24px;
    text-align: center;
    padding-top: 8px;
    display: block;

    &:hover {
        cursor: pointer;
        text-decoration: none;
    }

    &.orange {
        background-color: $primary-orange;

        &.active {
            background-color: $primary-orange-light;
        }
    }

    &.black {
        background-color: $black;

        &.active {
            background-color: $black-active;
        }
    }
}

@include media-breakpoint-up(md) {
    .call-to-action-button {
        color: $white-base;
        width: 300px;
        height: 60px;
        font-size: 30px;
        text-align: center;
        padding-top: 7px;
    }
}
